.markdown ol 
  @apply list-decimal pl-10

.markdown ul 
  @apply list-disc pl-10

.markdown h4 
  @apply text-lg border-b border-black my-5

.markdown ul 
  @apply list-disc pl-10

.markdown h1
  @apply text-3xl py-2

.markdown h2 
  @apply border-l-4 text-2xl pl-5 pr-5 pt-1.5 pb-1.5 mb-4 mt-4 border-gray-700

.markdown p
  @apply pl-5 pr-5

.whitespace-pre-wrap a[href]
  @apply text-blue-500